const CategoryReducer = (state = [], action: { type: any; payload: any }) => {
  switch (action.type) {
    case "ADD_CART":
      const newItems = action.payload.filter(
        (newItem: any) =>
          !state.some((item: any) => (item.product?._id === newItem.product?._id && item.entity._id == newItem.entity?._id))
      );
      return [...state, ...newItems];
    case "DELETE_CART":
      const cartIdToDelete = action.payload;
      const updatedStateAfterDelete = state.filter((item: any) => {
        return item._id !== cartIdToDelete;
      });
      return updatedStateAfterDelete;
    case "UPDATE_CART":
      const updatedItem = action.payload;
      const updatedStateAfterUpdate = state.map((item: any) =>
        item._id === updatedItem.cartId
          ? { ...item, quantity: updatedItem.quantity }
          : item
      );
      return updatedStateAfterUpdate;
    case "EMPTY_CART":
      return [];
    default:
      return state;
  }
};

export default CategoryReducer;
