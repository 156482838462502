import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import LikeButton from "components/LikeButton";
import AccordionInfo from "./AccordionInfo";
import { StarIcon } from "@heroicons/react/24/solid";
import BagIcon from "components/BagIcon";
import NcInputNumber from "components/NcInputNumber";
import { PRODUCTS } from "data/data";
import { useNavigate } from "react-router-dom";

import {
  NoSymbolIcon,
  ClockIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import IconDiscount from "components/IconDiscount";
import Prices from "components/Prices";
import toast from "react-hot-toast";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import detail1JPG from "images/products/detail1.jpg";
import detail2JPG from "images/products/detail2.jpg";
import detail3JPG from "images/products/detail3.jpg";
import Policy from "./Policy";
import ReviewItem from "components/ReviewItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import SectionPromo2 from "components/SectionPromo2";
import ModalViewAllReviews from "./ModalViewAllReviews";
import NotifyAddTocart from "components/NotifyAddTocart";
import SectionHero2 from "components/SectionHero/SectionHero2";
import ProductImageCarousel from "./ProductImageCarousel";
import AddToCatalogueModal from "components/AddToCatalogueModal";
import CatalogueCreateModal from "components/CatalogueModal";
import { getProductDetails } from "network/services/product";
import { Link, useLocation } from "react-router-dom";
import {
  addUserCartDetails,
  updateUserCartDetails,
} from "network/services/account";
import { useSelector, useDispatch } from "react-redux";

import {
  ADD_CART_ACTION,
  DELETE_CART_ACTION,
  UPDATE_CART_ACTION,
} from "../../redux/actions/cart";
import { Transition } from "@headlessui/react";
import { getLocalStorage, isAuth, userDetails } from "network/helper";
import { getCompanyID } from "network/services/company";

export interface ProductDetailPageProps {
  className?: string;
}

interface Product {
  category: string;
  company: string;
  currentPrice: number;
  defaultImage: number;
  description: string;
  entitiesList: any[]; // You may want to specify the type of entitiesList
  features: string[];
  images: string[];
  isDeleted: boolean;
  isDiscounted: boolean;
  isEnabled: boolean;
  isEnabledForBulk: boolean;
  isEnabledForPoints: boolean;
  isLimited: boolean;
  maxPrice: number;
  name: string;
  points: number;
  longDescription: string;
  priceSlabs: any;
  stock: number;
  subcategory: string;
  _id: string;
}

const ProductDetailPage: FC<ProductDetailPageProps> = ({ className = "" }) => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const CartSelector = useSelector((state: any) => state.CART);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sizeSelected, setSizeSelected] = React.useState("");
  const [qualitySelected, setQualitySelected] = React.useState(1);
  const [showModal, setShowModal] = useState(false);
  const [openAddToCatalogueModal, setOpenAddToCatalogueModal] = useState(false);
  const [isOpenModalViewAllReviews, setIsOpenModalViewAllReviews] = useState(false);
  const [product, setProduct] = useState<Product>();
  const [isAlreadyInCart, setIsAlreadyInCart] = useState(false);
  const [CompanyDetails, setCompanyDetails] = useState<any>(null);

  useEffect(() => {
    if (openAddToCatalogueModal === true) {
      setShowModal(false);
    }

  }, [openAddToCatalogueModal])

  const [bulkOrderEnabled, setBulkOrderEnabled] = useState(false);
  const [isHrAccess, setisHrAccess] = useState(false);
  const [isBulkPurchaseEnabledForUser, setisBulkPurchaseEnabledForUser] = useState(false);

  useEffect(() => {
    let cartItem = CartSelector.filter(
      (cartItem: any) => cartItem.product?._id === searchParams?.get("id")
    );

    if (cartItem.length > 1) {
      cartItem = cartItem.find((item: any) => {
        return item.entity._id == sizeSelected
      })
    } else cartItem = cartItem[0]

    let inCart = false;
    let quantity = 1;

    if (cartItem) {
      inCart = true;
      quantity = cartItem.quantity

      if (cartItem.entity && sizeSelected != cartItem.entity._id) {
        inCart = false;
        quantity = 1;
      }
    }

    setIsAlreadyInCart(inCart)
    setQualitySelected(quantity)
  }, [CartSelector.length, sizeSelected]);

  const notifyAddToCart = async (data: any) => {
    let obj = {
      product: data,
      quantity: qualitySelected,
      entity: data.entitiesList.find((entity: any) => entity._id == sizeSelected),
    };

    if (isAlreadyInCart) {
      toast.custom(
        (t) => (
          <Transition
            appear
            show={t.visible}
            className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
            enter="transition-all duration-150"
            enterFrom="opacity-0 translate-x-20"
            enterTo="opacity-100 translate-x-0"
            leave="transition-all duration-150"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-20"
          >
            <p className="block text-base font-semibold leading-none">
              Item Exist
            </p>
          </Transition>
        ),
        { position: "top-right", id: "nc-product-notify", duration: 1000 }
      );
    } else {
      let obj2 = {
        product_id: data?._id,
        quantity: qualitySelected,
        entity: sizeSelected,
      };
      try {
        const resp = await addUserCartDetails(obj2);

        if (resp?.data) {
          dispatch(ADD_CART_ACTION([{ ...obj, _id: resp.data._id }]));
          toast.custom(
            (t) => (
              <Transition
                appear
                show={t.visible}
                className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
                enter="transition-all duration-150"
                enterFrom="opacity-0 translate-x-20"
                enterTo="opacity-100 translate-x-0"
                leave="transition-all duration-150"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 translate-x-20"
              >
                <p className="block text-base font-semibold leading-none">
                  Added to cart!
                </p>
                <div className="border-t border-slate-200 dark:border-slate-700 my-4" />
                {renderProductCartOnNotify(data)}
              </Transition>
            ),
            { position: "top-right", id: "nc-product-notify", duration: 2000 }
          );
        }
      } catch (error) { }
    }
  };


  const renderProductCartOnNotify = (data: any) => {
    return (
      <div className="flex ">
        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
                <img
                  src={data?.images[data.defaultImage - 1]}
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="w-[50%]">
                <h3 className="text-base font-medium ">{data?.name}</h3>
                {
                  product && product.entitiesList && Array.isArray(product.entitiesList) && product.entitiesList.length > 0 && (
                    <p className="mt-1 text-sm text-slate-500 dark:text-slate-400 flex flex-row">
                      <span>Size{" "}:</span>
                      {product.entitiesList.map((item) => {
                        return (
                          <div key={item._id}>
                            {item._id === sizeSelected ? (
                              <p>{" "}{item.name}</p>
                            ) : null}
                          </div>
                        );
                      }) || "S"}
                    </p>
                  )
                }
                <p className="mt-1 text-sm text-slate-500 dark:text-slate-400 flex flex-row">
                  <span> Qty :{qualitySelected}</span>
                </p>
              </div>
              <Prices price={data?.currentPrice} className="mt-0.5" />
            </div>
          </div>
          <div className="flex flex-1 pt-3 items-end justify-between text-sm">
            <div className="flex">
              <Link
                to={"/cart"}
                className="font-medium text-primary-6000 dark:text-primary-500 "
              >
                View cart
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderSizeList = () => {
    if (!product?.entitiesList) {
      return null;
    }
    return (
      <div>
        <div className="flex justify-between font-medium text-sm">
          <label htmlFor="">
            {
              product && product.entitiesList && Array.isArray(product.entitiesList) && product.entitiesList.length > 0 && (
                <span className="">
                  Size:{" "}
                  {product?.entitiesList.map((item) => {
                    return (
                      <span key={item._id}>
                        {item._id === sizeSelected ? (
                          <span className="uppercase">{item.name}</span>
                        ) : null}
                      </span>
                    );
                  })}
                </span>
              )}
          </label>
        </div>
        <div className="flex flex-row gap-2 mt-3 grid grid-cols-5">
          {product.entitiesList.map((size: any, index: number) => {
            const isActive = size._id === sizeSelected;
            return (
              <div
                key={index}
                className={`relative min-w-16 text-center rounded-2xl border py-2 px-2 
                text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0 ${isActive
                    ? "bg-primary-6000 border-primary-6000 text-white hover:bg-primary-6000"
                    : "border-slate-300 dark:border-slate-600 text-slate-900 dark:text-slate-200 hover:bg-neutral-50 dark:hover:bg-neutral-700"
                  }`}
                onClick={() => {
                  setSizeSelected(size?._id);
                }}
              >
                {size?.name}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderSectionContent = () => {
    return (
      <div className="space-y-7 2xl:space-y-8">
        {/* ---------- 1 HEADING ----------  */}
        <div>
          <h2 className="text-2xl sm:text-3xl font-semibold">
            {product?.name}
          </h2>

          <div className="items-center mt-5">
            <div className="flex flex-row item-center gap-2">
              <Prices
                contentClass="py-1 px-2 md:py-1.5 md:px-3 text-lg font-semibold"
                price={product?.currentPrice}
              />
              <p className="text-red-400 line-through pt-2.5">₹ {product?.maxPrice}</p>
            </div>
            {getLocalStorage("company")?.isPointsEnabled && product?.isEnabledForPoints && (
              <p className={`text-sm text-slate-500 dark:text-slate-400 mt-2 `}>
                or claim by{" "}
                <span className="font-medium text-primary-6000 dark:text-primary-500 ">
                  {" "}
                  {product?.currentPrice} points{" "}
                </span>
              </p>
            )}
            <p className="text-sm text-slate-500 dark:text-slate-400 mt-2">
              <span className="font-medium text-primary-6000 dark:text-primary-500">{product?.stock}</span> in stock</p>
          </div>
        </div>

        {/* ---------- 3 VARIANTS AND SIZE LIST ----------  */}
        {/* <div className="">{renderVariants()}</div> */}
        <div className="">{renderSizeList()}</div>

        {/*  ---------- 4  QTY AND ADD TO CART BUTTON */}
        <div className="flex space-x-3.5">
          <div className="flex items-center justify-center bg-slate-100/70 dark:bg-slate-800/70 px-2 py-3 sm:p-3.5 rounded-full">
            <NcInputNumber
              defaultValue={qualitySelected}
              onChange={(value: number) => handleQtyChange(value)}
            />
          </div>

          {isAlreadyInCart ? (
            <ButtonSecondary className=" border border-slate flex-1 flex-shrink-0">
              {/* <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" /> */}
              <span className="ml-3">Added To cart</span>
            </ButtonSecondary>
          ) : (
            <ButtonPrimary
              className="flex-1 flex-shrink-0"
              onClick={() => notifyAddToCart(product)}
            >
              <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
              <span className="ml-3">Add to cart</span>
            </ButtonPrimary>
          )}
        </div>
        {isAuth()?.isHR && (
          <div className="flex space-x-3.5">
            <ButtonSecondary
              onClick={() => setShowModal(true)}
              className=" border border-slate flex-1 flex-shrink-0"
            >
              <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
              <span className="ml-3">Add to Catalog</span>
            </ButtonSecondary>
          </div>
        )}
        {isHrAccess && isBulkPurchaseEnabledForUser && bulkOrderEnabled && product && product.isEnabledForBulk && (
          <div>
            <h5 className="mb-2">Price slabs</h5>
            <table className="min-w-full">
              <thead
                className="bg-neutral-200"
                style={{ position: "sticky", top: 0 }}
              >
                <tr className="text-center ">
                  <th className=" shadow  text-primary-50 px-3 py-1">
                    Min.Qty
                  </th>
                  <th className=" shadow  text-primary-50 px-3 py-1">
                    Max.Qty
                  </th>
                  <th className=" shadow  text-primary-50 px-3 py-1">Price</th>
                </tr>
              </thead>
              <tbody style={{ overflow: "auto", maxHeight: "650px" }}>
                {product?.priceSlabs.length >= 1 &&
                  product?.priceSlabs?.map((item: any) => {
                    return (
                      <tr
                        style={{
                          borderBottom: "1px solid #ccc",
                          textAlign: "center",
                        }}
                        className=" border-slate bg-neutral-50"
                      >
                        <td className=" border-slate  px-3 py-2">
                          {item?.minQty}
                        </td>
                        <td>{item?.maxQty}</td>
                        <td>{item?.price}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}

        {/*  */}
        <hr className=" 2xl:!my-10 border-slate-200 dark:border-slate-700"></hr>
        {/*  */}

        {/* ---------- 5 ----------  */}
        <AccordionInfo
          data={[
            {
              name: "Description",
              content: `${product?.description}`,
            },
            {
              name: "Features",
              content: ` ${product?.features
                .map((item) => {
                  return `<li>${item}</li>`;
                })
                .join("")}`,
            },
          ]}
        />

        {/* ---------- 6 ----------  */}
        <div className="hidden xl:block">{/* <Policy /> */}</div>
      </div>
    );
  };

  const renderDetailSection = () => {
    return (
      <div className="">
        <h2 className="text-2xl font-semibold">Product Details</h2>
        <div className="prose prose-sm sm:prose dark:prose-invert sm:max-w-4xl mt-7">
          {/* <p>{product?.longDescription}</p> */}
          <p dangerouslySetInnerHTML={{ __html: !!product ? product?.longDescription : "" }} />
        </div>
      </div>
    );
  };

  const handleQtyChange = (value: number) => {
    setQualitySelected(value);
    if (isAlreadyInCart) updateCart(product?._id, value);
  };

  // API INT

  const getProductDetailsFromAPi = async () => {
    try {
      const response = await getProductDetails(searchParams.get("id"));
      setProduct(response?.data);
      setSizeSelected(response?.data?.entitiesList[0]?._id);
    } catch (error) { }
  };

  const updateCart = async (item_id: any, qty: number) => {
    const cartItem = CartSelector.find((e: any) => {
      if (e.entity) return e.entity._id == sizeSelected && e.product._id == item_id
      else return e.product._id == item_id
    })
    let requestObj = {
      cartId: cartItem._id,
      quantity: +qty,
    };
    try {
      const response = await updateUserCartDetails(requestObj);
      if (response?.data) {
        dispatch(UPDATE_CART_ACTION(requestObj));
      }
    } catch (error) { }
  };
  // API INT

  const initCompanyDetails = async () => {
    let obj = {
      hostUrl: String(window.location.host).includes("localhost:3000")
        ? process.env.REACT_APP_DEFAULT_COMPANY_URL
        : String(window.location.host).split('/')[0],
    };

    try {
      const resp = await getCompanyID(obj);

      if (resp?.data?._id) {
        const companyData = resp.data;
        if (!companyData.isEcommerceEnabled || !companyData.isPointsEnabled) {
          navigate("/home");
        }
        setBulkOrderEnabled(companyData.isBulkEnabled);
        setCompanyDetails(companyData);
      }
    } catch (error) {
      console.error("Error fetching company details:", error);
    }
  };

  const initUserDetails = async () => {

    try {
      const resp = await userDetails();
      if (resp) {
        setisHrAccess(resp.isHR)
        setisBulkPurchaseEnabledForUser(resp.isBulkPurchaseEnabled)
      }
    } catch (error) {
      console.error("Error fetching company details:", error);
    }
  };




  useEffect(() => {
    initCompanyDetails();
    initUserDetails();
    const company = getLocalStorage("company");
    getProductDetailsFromAPi();
    const user = getLocalStorage("user_details")
    if (!user || !company) {
      navigate("/login")
    }
  }, []);

  return (
    <div>
      {product?.images && (
        <div className={`nc-ProductDetailPage ${className}`}>
          <AddToCatalogueModal
            product_id={product?._id}
            show={showModal}
            onCloseModalReportItem={() => setShowModal(false)}
            onclick={() => setOpenAddToCatalogueModal(true)}
          />
          <CatalogueCreateModal
            show={openAddToCatalogueModal}
            onCloseModalReportItem={() => setOpenAddToCatalogueModal(false)}
          />
          {/* MAIn */}
          <main className="container mt-5 lg:mt-11">
            <div className=" h-full lg:flex">
              <div className="w-full  lg:w-[55%]">
                <ProductImageCarousel DATA={product?.images} />
              </div>
              <div className="w-full lg:w-[45%] pt-10 lg:pt-0 lg:pl-7 xl:pl-9 2xl:pl-10">
                {renderSectionContent()}
              </div>
            </div>

            {/* DETAIL AND REVIEW */}
            <div className="mt-12 sm:mt-16 space-y-10 sm:space-y-16">
              {renderDetailSection()}

              <hr className="border-slate-200 dark:border-slate-700" />
            </div>
          </main>
        </div>
      )}
    </div>
  );
};

export default ProductDetailPage;
