import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { checkEmail, login, verifyTwoStepCode } from "network/services/auth";
import { getUserDetails as getUserDetailsFromAPI } from "network/services/account";
import "./login.css";
import {
  authenticate,
  getLocalStorage,
  isAuth,
  setLocalStorage,
} from "network/helper";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import ShowIcon from "images/visible.svg";
import HideIcon from "images/blocked.svg";
import { getCompanyID } from "network/services/company";
import NcImage from "shared/NcImage/NcImage";
import { setCompanyStyles } from "utils/setCompanyStyles";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import axios from "axios";
import SuccessModal from "components/SuccessModal";
import ErrorModal from "components/ErrorModal";
import ModalQuickView from "./Modal";

export interface PageLoginProps {
  className?: string;
}

interface twostepCode {
  hashedOtp: string,
  code: number;
}
interface checkEmail {
  email: string;
  company_id: string;
}


const PageLogin: FC<PageLoginProps> = ({ className }) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [values, setValues] = useState({
    company_id: "",
    email: "",
    password: "",
  });

  const [values1, setValues1] = useState({
    company_id: "",
    email: "",
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [companyImage, setCompanyImage] = useState<any>(null);
  const [showTwoStep, setShowTwoStep] = useState(false);
  const [EmailAuthEnabled, setEmailAuthEnabled] = useState(false);
  const [DiableInput, setDiableInput] = useState(false);
  const [DisclaimerText, setDisclaimerText] = useState('');
  const [HashedOtpPass, setHashedOtpPass] = useState('');
  const [loading, setloading] = useState(false);
  const [showModalQuickView, setShowModalQuickView] = React.useState(false);
  const [showResentButtonButton, setshowResentButtonButton] = React.useState(false);


  const [twoStepCode, setTwoStepCode] = useState<number | undefined>();
  const [loginresponse, setloginResponse] = useState({});
  const [numberCount, setnumberCount] = useState<number | undefined>()
  const [isChecked, setIsChecked] = useState(true);
  const handleCheckboxChange = (e: any) => {
    setIsChecked(e.target.checked);
  };

  useEffect(() => {
    console.log(twoStepCode, "Cjhf")
    const count: any = twoStepCode?.toString();
    if (count?.length === 4) {
      setDiableInput(true);
    }

  }, [twoStepCode])

  const handleInputChange = (e: any) => {
    let { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  const handleInputChangeForTwoStepCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (/^\d{0,4}$/.test(value)) {
      setTwoStepCode(Number(value));
    }
  };
  const [isReadMoreVisible, setIsReadMoreVisible] = useState(false);

  const initCompanyDetails = async () => {
    let obj = {
      hostUrl: String(window.location.host).includes("localhost:3000")
        ? process.env.REACT_APP_DEFAULT_COMPANY_URL
        : String(window.location.host).split("/")[0],
    };
    const resp = await getCompanyID(obj);
    if (resp?.data?._id) {
      const company = resp.data;
      console.log(company, "company")
      if (company?.isDisclaimer) {
        setDisclaimerText(company.disclaimer);
        setIsChecked(false);
        if (company.disclaimer.length > 30) {
          setIsReadMoreVisible(true);
        }
      }
      if (company.emailAuth != undefined && company.emailAuth === true) {
        setEmailAuthEnabled(true);
      }
      setLocalStorage("company", company);
      setValues({
        ...values,
        company_id: company._id,
      });
      setValues1({
        ...values,
        company_id: company._id,
      });
      setCompanyImage(company.loginImage);
      setCompanyStyles(document, company);
    } else {
      navigate("/not-found");
    }
  };
  const { instance, accounts } = useMsal();

  async function handleLogout() {
    try {
      const red = instance.logoutPopup();
      console.log(red);
    } catch (error) {
      console.error("Logout failed:", error);
    }
  }

  const handleModal = () => {
    setShowModalQuickView(true);
  }

  // const handleLoginWithAuth = async (e: any) => {
  //   e.preventDefault();
  //   const loading = toast.loading("requesting...");
  //   let loginResponse: any;
  //   try {
  //     loginResponse = await instance.loginPopup(loginRequest);
  //     console.log(loginResponse, "loginResponse");
  //     const email = loginResponse.account.username;
  //     if (!email) {
  //       return;
  //     }

  //     setValues1((prevState) => ({
  //       ...prevState,
  //       email: loginResponse.account.username,
  //     }));

  //     if (!values1?.company_id) {
  //       alert("Something went wrong please try again after some time");
  //       return;
  //     }

  //     const checkEmailData: CheckEmail = {
  //       email: loginResponse.account.username.toLowerCase(), // Ensure email is lowercase
  //       company_id: values1.company_id || " ", // Handle potential undefined company_id
  //     };
  //     const response: any = await checkEmail(checkEmailData);
  //     if (response?.data?.token) {
  //       authenticate(response, () => (
  //         toast.dismiss(loading),
  //         toast.success("Login Success"),
  //         getUserDetails()
  //       ));
  //     } else {
  //       toast.dismiss(loading);
  //       setShowSuccess(true);
  //       toast.error(response?.errRes.data?.message);
  //     }
  //   } catch (error: any) {
  //     toast.dismiss(loading);
  //     if (error?.errRes?.data?.message) {
  //       toast.error(error?.errRes?.data?.message);
  //     }
  //   }
  // };

  const handleCloseErrorModal = () => {
    setShowSuccess(false);
  };

  const handleLoginWithemailAuth = async (e: any) => {
    e.preventDefault();
    if (!values?.company_id) {
      alert("Something went wrong please try again after some time");
      return;
    }
    console.log(values);
    const loading = toast.loading("requesting...");
    try {
      const response: any = await login({ ...values, email: values.email.toLowerCase() });
      console.log(response, "response")
      setloginResponse(response);
      if (response?.data?.hashedOtp) {
        setshowResentButtonButton(false);
        setHashedOtpPass(response?.data?.hashedOtp);
        // localStorage.setItem("hashedOtp", response?.data?.hashedOtp)
        setShowTwoStep(true);
        toast.success("Verification code sent to your email");
        toast.dismiss(loading);
        setloginResponse(response);
      }
      else {
        toast.dismiss(loading);
        toast.error(response?.errRes.data?.message);
      }
    } catch (error: any) {
      toast.dismiss(loading);
      toast.error(error?.errRes.data?.message);
    }
  };

  const handleLoginWithoutemailAuth = async (e: any) => {
    setloading(true);
    e.preventDefault();
    if (!values?.company_id) {
      alert("Something went wrong please try again after some time");
      setTimeout(() => {
        setloading(false);
      }, 1000);
      return;
    }
    const loading = toast.loading("requesting...");
    try {
      const response: any = await login({ ...values, email: values.email.toLowerCase() });
      if (response?.data?.token) {
        authenticate(
          response,
          () => (
            toast.dismiss(loading),
            toast.success("Login Success"),
            getUserDetails(),
            setTimeout(() => {
              setloading(false);
            }, 2000)
          )
        );
      } else {
        toast.dismiss(loading);
        toast.error(response?.errRes.data?.message);
        setTimeout(() => {
          setloading(false);
        }, 1000);
      }
    } catch (error: any) {
      toast.dismiss(loading);
      toast.error(error?.errRes.data?.message);
      setTimeout(() => {
        setloading(false);
      }, 1000);
    }
  };

  const handleTwoStepSubmit = async (e: any) => {
    e.preventDefault();
    const loading = toast.loading("Verifying code...");

    let code: number = twoStepCode ? twoStepCode : 0;
    const twostepCodeData: twostepCode = {
      hashedOtp: HashedOtpPass,
      code,
    };
    try {
      const response: any = await verifyTwoStepCode(twostepCodeData);
      console.log(response, "res")
      if (response?.data?.token) {
        authenticate(response, () => (
          toast.dismiss(loading),
          toast.success("Login Success"),
          getUserDetails()
        ));
      } else {
        toast.dismiss(loading);
        console.log(response, "response")
        if (response?.errRes === "OTP expired") {
          setshowResentButtonButton(true);
        }
        toast.error(response?.errRes);
      }
    } catch (error: any) {
      toast.dismiss(loading);
      toast.error(error);
    }
  };

  const getUserDetails = async () => {
    try {
      const response: any = await getUserDetailsFromAPI();
      setLocalStorage("user_details", response?.data);
      navigate("/home");
    } catch (error) { }
  };

  useEffect(() => {
    initCompanyDetails();
    if (isAuth()) {
      navigate("/home");
    }
  }, []);

  return (
    <div className={`nc-PageLogin ${className} flex flex-row`} data-nc-id="PageLogin">
      {companyImage && (
        <div className="flex-1 hidden sm:inline-block">
          <NcImage
            src={companyImage}
            alt="Company"
            className="h-full w-full object-fit-cover"
            containerClassName="h-full w-full"
          />
        </div>
      )}
      <div className="flex-1">
        <Helmet>
          <title>Login</title>
        </Helmet>
        <div className="container mb-24 lg:mb-32">
          <img
            className="my-10 mx-auto flex items-center w-25 h-14"
            src={getLocalStorage("company").logo}
          ></img>
          {!showTwoStep ? (
            <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
              Login
            </h2>
          ) : (
            <h2 className="my-20 flex text-center flex justify-center items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
              Two Step Authentication
            </h2>
          )}

          <div className="max-w-md mx-auto space-y-6">
            {/* FORM */}
            {!showTwoStep ? (
              <>
                {EmailAuthEnabled ? (
                  <form onSubmit={handleLoginWithemailAuth} className="grid grid-cols-1 gap-6">
                    <label className="block">
                      <span className="text-neutral-800 dark:text-neutral-200">
                        Company email address
                      </span>
                      <Input
                        required
                        type="email"
                        placeholder="example@example.com"
                        className="mt-1"
                        name="email"
                        onChange={handleInputChange}
                      />
                    </label>
                    <label className="block">
                      <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                        Password
                        <Link to="/forgot-pass" className="text-sm text-green-600">
                          Forgot password?
                        </Link>
                      </span>
                      <div className="relative">
                        <Input
                          name="password"
                          placeholder="Enter Password Here"
                          onChange={handleInputChange}
                          type={showPassword ? 'text' : 'password'}
                          className="mt-1"
                          required
                        />
                        <button
                          type="button"
                          onClick={() => setShowPassword(!showPassword)}
                          className="absolute right-0 -translate-x-[50%] top-0 translate-y-[50%]"
                        >
                          <img src={showPassword ? HideIcon : ShowIcon} alt="Toggle Password Visibility" />
                        </button>
                      </div>
                    </label>
                    <ButtonPrimary type="submit">Continue</ButtonPrimary>
                  </form>
                ) : (
                  <form onSubmit={handleLoginWithoutemailAuth} className="grid grid-cols-1 gap-6">
                    <label className="block">
                      <span className="text-neutral-800 dark:text-neutral-200">
                        Company email address
                      </span>
                      <Input
                        required
                        type="email"
                        placeholder="example@example.com"
                        className="mt-1"
                        name="email"
                        onChange={handleInputChange}
                      />
                    </label>
                    <label className="block">
                      <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                        Password
                        <Link to="/forgot-pass" className="text-sm text-green-600">
                          Forgot password?
                        </Link>
                      </span>
                      <div className="relative">
                        <Input
                          name="password"
                          placeholder="Enter Password Here"
                          onChange={handleInputChange}
                          type={showPassword ? 'text' : 'password'}
                          className="mt-1"
                          required
                        />
                        <button
                          type="button"
                          onClick={() => setShowPassword(!showPassword)}
                          className="absolute right-0 -translate-x-[50%] top-0 translate-y-[50%]"
                        >
                          <img src={showPassword ? HideIcon : ShowIcon} alt="Toggle Password Visibility" />
                        </button>
                      </div>
                    </label>
                    <ButtonPrimary disabled={loading} type="submit">Continue</ButtonPrimary>
                  </form>
                )}
              </>
            ) : (
              <form onSubmit={handleTwoStepSubmit} className="grid grid-cols-1 gap-6">
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    Enter the verification code sent to your email
                  </span>

                  <Input
                    required
                    type="number"
                    placeholder="Verification Code"
                    className="mt-1"
                    maxLength={4}
                    name="twoStepCode"
                    value={twoStepCode}
                    onChange={handleInputChangeForTwoStepCode}
                  />
                </label>
                <div>
                  {DisclaimerText && (
                    <div className="flex">
                      <input
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        className="mt-1" type="checkbox"></input>
                      <p className="mx-2 text-neutral-800 dark:text-neutral-200">
                        {DisclaimerText.substring(0, 50)}
                        {isReadMoreVisible && (
                          <p
                            className="text-green-600  cursor-pointer"
                            onClick={handleModal}
                          >
                            ...Read More
                          </p>
                        )}
                      </p>

                    </div>
                  )}
                </div>
                {showResentButtonButton && (
                  <div className="cursor-pointer flex justify-end text-green-600">
                    <p className="text-semibold text-sm" onClick={handleLoginWithemailAuth}> Resend OTP</p>
                  </div>
                )}
                <ButtonPrimary disabled={!isChecked} type="submit">Verify</ButtonPrimary>
              </form>
            )}

            {getLocalStorage("company")?.isSelfSignUpEnabled && (
              <span className="block text-center text-neutral-700 dark:text-neutral-300">
                New user?{' '}
                <Link className="text-green-600" to="/signup">
                  Create an account
                </Link>
              </span>
            )}

            <span className="block text-center text-neutral-700 dark:text-neutral-300">
              Finding a problem? Go to{' '}
              <Link className="text-green-600" to="/faq">
                help{' '}
              </Link>
            </span>
          </div>
        </div>
      </div>
      <ErrorModal
        path="/login"
        successMessage="Authentication Failed"
        redirectMessage="Redirection to orders page..."
        showModal={showSuccess}
        closeModal={handleCloseErrorModal}
      />

      <ModalQuickView
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
      />
    </div >
  );
};

export default PageLogin;
