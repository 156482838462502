import { Navigate, useNavigate } from "react-router-dom";

interface Props {
    tableHeader: any;
    tableData: any;
    path: string;
    isCLickable?: boolean
}

const DynamicTable = ({ tableData, tableHeader, path, isCLickable }: Props) => {
    const navigate = useNavigate();

    const handleNavigation = (_id: string) => {

        if (isCLickable === true || isCLickable === undefined) {
            const selectedRow: any = tableData.filter((tableItem: any) => tableItem._id == _id)
            navigate(`${path}?id=${_id}`, { state: { rowData: selectedRow[0] } })
        }

    };
    return (
        <div className="overflow-x-auto mb-1 m-4">
            <table className="min-w-full">
                <thead
                    className="bg-neutral-200"
                    style={{ position: "sticky", top: 0 }}
                >
                    <tr className="text-left ">
                        {tableHeader.map((header: any) => (
                            <th
                                key={header?.name}
                                className=" shadow  text-primary-50 px-4 py-4"
                            >
                                {header?.name || "NA"}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody style={{ overflow: "auto", maxHeight: "650px" }}>
                    {tableData.map((row: any, rowIndex: any) => (
                        <tr
                            onClick={() => handleNavigation(row["_id"])}
                            style={{ borderBottom: "1px solid #ccc" }}
                            className=" border-slate hover:bg-neutral-300"
                        >
                            {tableHeader.map((header: any | string) => (
                                <td key={header} className=" border-slate  px-4 py-2">
                                    {row[header.key] || "NA"}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default DynamicTable;
