
import { Helmet } from "react-helmet-async";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DynamicTable from "./CustomTable/page";
import Pagination from "./Pagination/page";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { useState, useEffect, ChangeEvent } from "react";
import ButtonThird from "shared/Button/ButtonThird";
import Checkbox from "shared/Checkbox/Checkbox";
import { Link } from "react-router-dom";
import { listUsersByCompanyId } from "network/services/HR";
import { toast } from "react-hot-toast";
import ButtonDanger from "shared/Button/ButtonDanger";
import Button from "shared/Button/Button";

interface TableItem {
  slno: number;
  _id: string;
  name: string;
  email: string;
  isEnabled: string;
  isVerified: string;
}

interface ApiResponse {
  data?: {
    users: TableItem[];
    totalCount: number;
    totalPages: number;
    currentPage: number;
  };
  message?: string;
}

const HrDashboard: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [tableData, setTableData] = useState<TableItem[]>([]);
  const [filteredTableData, setFilteredTableData] = useState<TableItem[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const itemsPerPage = 10;

  const tableHeaders = [
    { name: "Slno", key: "slno" },
    { name: "Name", key: "name" },
    { name: "Employee Email", key: "email" },
    { name: "Enabled?", key: "isEnabled" },
    { name: "Verified?", key: "isVerified" },
  ];

  const getUsersByCompanyId = async (searchQuery: string = '') => {
    try {
      const resp: ApiResponse = await listUsersByCompanyId(currentPage, itemsPerPage, searchQuery);
      console.log(resp, "resp")
      if (resp?.data?.users) {
        const userData = resp?.data?.users.map((user, index): TableItem => ({
          slno: (currentPage - 1) * itemsPerPage + index + 1,
          _id: user._id,
          name: user.name,
          email: user.email,
          isEnabled: user.isEnabled ? "yes" : "no",
          isVerified: user.isVerified ? "yes" : "no",
        }));
        setTableData(userData);
        setFilteredTableData(userData);
        setTotalPages(resp?.data?.totalPages);
      } else {
        toast.error(resp?.message || "Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    getUsersByCompanyId(searchQuery);
  }, [currentPage]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleSearch = () => {
    setCurrentPage(1);
    getUsersByCompanyId(searchQuery);
  };

  const handleClearSearch = async () => {
    setSearchQuery("");
    setCurrentPage(1);
    try {
      const resp: ApiResponse = await listUsersByCompanyId(1, itemsPerPage, searchQuery);
      if (resp?.data?.users) {
        const userData = resp.data.users.map((user, index): TableItem => ({
          slno: (currentPage - 1) * itemsPerPage + index + 1,
          _id: user._id,
          name: user.name,
          email: user.email,
          isEnabled: user.isEnabled ? "yes" : "no",
          isVerified: user.isVerified ? "yes" : "no",
        }));
        setTableData(userData);
        setFilteredTableData(userData);
        setTotalPages(resp.data.totalPages);
      } else {
        toast.error(resp?.message || "Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const renderSearchForm = () => (
    <div className="flex items-center space-x-2 w-full">
      <div className="flex-1 py-2 text-slate-900 dark:text-slate-100">
        <div className="bg-slate-50 dark:bg-slate-800 flex items-center px-5 h-full rounded">
          <input
            type="text"
            placeholder="Search by name or email of the employee"
            className="border-none bg-transparent focus:outline-none focus:ring-0 w-full text-base"
            value={searchQuery}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <ButtonPrimary onClick={handleSearch}>Search</ButtonPrimary>
      <Button className="rounded-full border border-red-400 hover:bg-red-200 hover:text-white text-md font-bold" onClick={handleClearSearch}>Clear</Button>
    </div>
  );

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Hr Points</title>
      </Helmet>

      <div className="container">
        <div className="mb-12 sm:mb-16">
          <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
            <Link to="/hr-dashboard" className="">
              My Menu
            </Link>
            <span className="text-xs mx-1 sm:mx-1.5">/</span>
            <span className="underline">Points Credited</span>
          </div>
        </div>
        <div className="mt-6 rounded mb-24 lg:mt-4 border border-slate">
          <div className="bg-white rounded">
            <div className="flex items-center justify-between pb-4 m-4 pt-4">
              {renderSearchForm()}
            </div>
            <DynamicTable path="/hr-employee-points-details" tableData={filteredTableData} tableHeader={tableHeaders} />
            <div className="flex justify-center">
              <div className="my-3 p-3">
                <Pagination
                  currentPage={currentPage}
                  totalCount={totalPages * itemsPerPage}
                  pageSize={itemsPerPage}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HrDashboard;
