import { FC, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Prices from "components/Prices";
import NcInputNumber from "components/NcInputNumber";
import { useDispatch } from "react-redux";
import { UPDATE_CART_ACTION, DELETE_CART_ACTION } from "../../redux/actions/cart";
import { updateUserCartDetails, deleteUserCartDetails, getUserDetails } from "network/services/account";
import { getCompanyID } from "network/services/company";

interface CartProductProps {
  item: any;
  index: number;
  deleteFromCart: (index: number) => void;
}

const CartProduct: FC<CartProductProps> = ({
  item,
  index,
  deleteFromCart
}) => {
  const { images, price, name, currentPrice, _id, defaultImage, priceSlabs, isEnabledForBulk } = item?.product;
  const [adjustedPrice, setAdjustedPrice] = useState(currentPrice);
  const dispatch = useDispatch();
  const [company, setCompany] = useState<any>(null);
  const [userDetails, setUserDetails] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const initCompanyDetails = async () => {
      let obj = {
        hostUrl: String(window.location.host).includes("localhost:3000")
          ? process.env.REACT_APP_DEFAULT_COMPANY_URL
          : String(window.location.host).split('/')[0],
      };

      try {
        const resp = await getCompanyID(obj);
        if (resp?.data?._id) {
          const companyData = resp.data;
          if (!companyData.isEcommerceEnabled || !companyData.isPointsEnabled) {
            navigate("/home");
          }
          setCompany(companyData);
        }
      } catch (error) {
        console.error("Error fetching company details:", error);
      }
    };

    const getUserDetail = async () => {
      const userDetails = await getUserDetails();
      setUserDetails(userDetails.data);
    };

    getUserDetail();
    initCompanyDetails();
  }, [navigate]);

  useEffect(() => {
    if (item.quantity) {
      setAdjustedPrice(getAdjustedPrice(item.quantity));
    }
  }, [item.quantity, company, userDetails]);

  const getAdjustedPrice = (quantity: number) => {
    if (priceSlabs && priceSlabs.length > 0 && company?.isBulkEnabled && userDetails?.isBulkPurchaseEnabled && userDetails?.isHR && isEnabledForBulk) {

      let lastMatchingSlabPrice = null;

      for (const slab of priceSlabs) {
        if (quantity >= slab.minQty && quantity <= slab.maxQty) {
          return slab.price;
        }
        lastMatchingSlabPrice = slab.price;
      }

      if (quantity > priceSlabs[priceSlabs.length - 1].maxQty) {
        return lastMatchingSlabPrice;
      }
    }

    return currentPrice;
  };


  const updateCart = async (item_id: string, qty: number) => {
    const requestObj = {
      cartId: item._id,
      quantity: qty
    };

    try {
      const response = await updateUserCartDetails(requestObj);
      const newPrice = getAdjustedPrice(qty);
      setAdjustedPrice(newPrice);
      if (response?.data) {
        dispatch(UPDATE_CART_ACTION(requestObj));
      }
    } catch (error) {
      console.error("Error updating cart:", error);
    }
  };

  const removeFromCart = async (item_id: string) => {
    let obj = {
      products: [item_id],
    };

    try {
      const response = await deleteUserCartDetails(obj);
      if (response?.data) {
        deleteFromCart(index);
        dispatch(DELETE_CART_ACTION(item_id));
      }
    } catch (error) {
      console.error("Error removing item from cart:", error);
    }
  };

  return (
    <div key={index} className="relative flex py-8 sm:py-10 xl:py-12 first:pt-0 last:pb-0">
      <div className="relative w-32 h-32 sm:w-40 sm:h-40 md:w-32 md:h-32 lg:w-32 lg:h-32 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
        <img
          src={images[defaultImage - 1]}
          alt={name}
          className="w-full h-full object-cover"
        />
        <Link to={`/product-detail?id=${item.product._id}`} className="absolute inset-0"></Link>
      </div>

      <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
        <div>
          <div className="flex justify-between ">
            <div className="flex-[1.5] ">
              <h3 className="text-base font-semibold">
                <Link to={`/product-detail?id=${item.product._id}`}>{name}</Link>
              </h3>
              <div className="mt-1.5 sm:mt-2.5 flex text-sm text-slate-600 dark:text-slate-300">
                <div className="flex items-center space-x-1.5 uppercase">
                  <span>{item.entity?.name ? "Size:" : ""}{item.entity?.name ? item.entity?.name : ""}</span>
                </div>
              </div>

              <div className="mt-3 flex justify-between w-full sm:hidden relative">
                <NcInputNumber
                  onChange={(value: any) => updateCart(_id, value)}
                  className="relative z-10"
                  defaultValue={item?.quantity}
                />
                <Prices
                  contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                  price={adjustedPrice}
                />
              </div>
            </div>

            <div className="hidden sm:block text-center relative">
              <NcInputNumber
                onChange={(value: any) => updateCart(_id, value)}
                className="relative z-10"
                defaultValue={item?.quantity}
              />
            </div>

            <div className="hidden flex-1 sm:flex justify-end">
              <Prices price={adjustedPrice} className="mt-0.5" />
            </div>
          </div>
        </div>

        <div className="flex mt-auto pt-4 items-end justify-between text-sm">
          <div
            onClick={() => removeFromCart(item._id)}
            className="cursor-pointer relative z-10 flex items-center mt-3 font-medium text-primary-6000 hover:text-primary-500 text-sm "
          >
            <span>Remove</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartProduct;
