import { Helmet } from "react-helmet-async";
import SectionHero2 from "components/SectionHero/SectionHero2";
import Heading from "components/Heading/Heading";
import MyVoucherAddressField from "./MyVoucherAddressField";
import { useEffect, useState, FC, ChangeEventHandler } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProductSelectionSection from "./ProductSelection";
import { getVoucherProducts } from "network/services/account";
import { getLocalStorage } from "network/helper";
import { placeVoucherOrder, placeVoucherOrderv2 } from "network/services/order";
import { toast } from "react-hot-toast";
import SuccessModal from "components/SuccessModal";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import AddressForm from "containers/CartPage/AddressForm";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { verifyAddress } from "utils/verifyAddress";
import OrderConfirmation from "./OrderConfirmationPopup";

interface VoucherOrderData {
    department?: string,
    name: string,
    email: string,
    address: string,
    district?: string,
    altContact?: string,
    contactNo: string,
    state: string,
    city: string,
    country: string,
    pincode: string,
    purchaseOrder?: string,
    voucherType: string,
    data: Array<{ product: string, entity: string | null }>,
    userVoucherId: string,
}


const MyVoucherDetailsPage: FC = () => {
    const [showOrderConfirmation, setShowOrderConfirmation] = useState(false);
    const handleClosePopup = () => setShowOrderConfirmation(false);

    const handleOpenPopup = () => {
        console.log("Hello")
        setShowOrderConfirmation(true)
    }

    const location = useLocation()
    const navigate = useNavigate()
    const [voucher, setVoucher] = useState<any>(null)
    const [userDetails, setUserDetails] = useState<{ username: string, email: string }>({ username: "", email: "" })
    const [data, setData] = useState<Array<{ category: string, selected: { product: string, entity: string | null }, products: Array<any> }>>([])
    const [isCombo, setIsCombo] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [ConfirmOrder, setConfirmOrder] = useState(false);
    const [buttonLoading, setbuttonLoading] = useState(false)
    const [address, setAddress] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNo: "",
        address: "",
        district: "",
        city: "",
        state: "",
        country: "India",
        altContact: "",
        pincode: "",
        _id: ""
    })

    const handleAddressChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement> = (e) => {
        if (checkLeadingSpace(e.target.value, e.target.name)) return
        if ((e.target.name === "altContact" || e.target.name === "phoneNo") && e.target.value.length > 10) return toast.error("Maximum length is 10 digits")
        setAddress((state) => {
            return {
                ...state,
                [e.target.name]: e.target.value
            }
        })
    }


    const fetchVoucherProducts = async (voucherId: string) => {
        const res = await getVoucherProducts(voucherId)
        if (!res.data) {
            if (res.errRes.data && res.errRes.data.message)
                toast.error(res.errRes.data.message)
            navigate("/my-vouchers")
            return
        }
        const formattedData = res.data.map((item: any) => ({
            category: item.nameOfCategory,
            selected: {
                product: null,
                entity: null,
            },
            products: item.products
        }))
        setData(formattedData)
    }

    useEffect(() => {
        if (!location.state || location.state == null) {
            navigate('/my-vouchers')
            return
        }
        console.log(location.state)
        setVoucher(location.state)
        console.log(location.state, "location.state")
        if (location.state && !(location.state.isClaimed)) {
            fetchVoucherProducts(location.pathname.split('/').slice(-1)[0])
            if (location.search.split('=')[1] == "true")
                setIsCombo(true)
        }
        const userDetails = getLocalStorage('user_details')
        if (userDetails) {
            setUserDetails({ username: userDetails.name, email: userDetails.email })
            setAddress(state => ({ ...state, email: userDetails.email }))
        }
    }, [location])

    const handleSelectComboProduct = (categoryIndex: number, productId: string, entityId: string | null) => {
        setData((state) => {
            const tempState = [...state]
            tempState[categoryIndex].selected = {
                product: productId,
                entity: entityId
            }
            return tempState;
        })
    }

    const handleSelectProduct = (categoryIndex: number, productId: string, entityId: string | null) => {
        console.log(entityId, "Entity")
        setData((state) => {
            const tempState = [...state].map((category: any, index: number) => {
                const tempCat = { ...category }

                if (categoryIndex == index) tempCat.selected = { product: productId, entity: entityId }
                else tempCat.selected = { product: null, entity: null }

                return tempCat
            })
            return tempState
        })
    }

    const getSelectedItems = () => {
        if (isCombo)
            return data.map((cat) => ({ ...(cat.selected) }))
        else {
            const cat = data.find((cat) => cat.selected.product != null);
            console.log(cat?.selected.entity, "Cata")
            if (cat) return [cat.selected]
            else return []
        }
    }

    const checkProductsSelected = () => {
        const selectedProducts = getSelectedItems()
        console.log(selectedProducts, "selectedProducts")
        if (selectedProducts.length == 0) {
            toast.error("Please Choose The Products Available")
            return false;
        }
        if (isCombo && selectedProducts.length < data.length) {
            toast.error("Please Choose A Product From Each Category")
            return false;
        }
        return true;
    }

    const handlePlaceOrder = async (contactDetails: { name: string, contactNo: string, altContact: string, address: string }) => {
        const selectedProducts = getSelectedItems()
        const orderDetails = {
            ...contactDetails,
            data: selectedProducts,
            purchaseOrder: (voucher.purchaseOrderRef) ? voucher.purchaseOrderRef : "qeqwer",
            voucherType: voucher.voucherTypeId._id,
            userVoucherId: voucher._id,
            email: userDetails.email
        }
        const loading = toast.loading("requesting...");
        try {
            const response: any = await placeVoucherOrder(orderDetails);
            if (response?.data) {
                toast.dismiss(loading)
                setShowSuccess(true)
            } else {
                toast.dismiss(loading);
                toast.error(response?.errRes.data?.message);
            }
        } catch (error: any) {
            toast.dismiss(loading);
            toast.error(error?.errRes.data?.message);
        }
    }

    const checkLeadingSpace = (input: string, name: string) => {
        if (name == "firstName" && input == " " && address.firstName == "") return true
        if (name == "lastName" && input == " " && address.lastName == "") return true
        if (name == "address" && input == " " && address.address == "") return true
        if (name == "email" && input == " " && address.email == "") return true
        if (name == "district" && input == " " && address.district == "") return true
        if (name == "city" && input == " " && address.city == "") return true
        if (name == "state" && input == " " && address.state == "") return true

        return false
    }

    const handleSubmit = async () => {
        // setbuttonLoading(true);

        if (!checkProductsSelected()) {
            setTimeout(() => {
                setbuttonLoading(false);
            }, 1000);
            return;
        }
        const verifyMessage: string | null = verifyAddress(address)
        if (verifyMessage != null) {
            setTimeout(() => {
                setbuttonLoading(false);
                toast.error(verifyMessage)
            }, 1000);
            return
        }
        const selectedProducts = getSelectedItems()
        const voucherOrderDetails: VoucherOrderData = {
            name: address.firstName + " " + address.lastName,
            email: address.email,
            contactNo: address.phoneNo,
            address: address.address,
            city: address.city,
            state: address.state,
            country: address.country,
            pincode: address.pincode,
            data: selectedProducts,
            department: voucher.department,
            voucherType: voucher.voucherTypeId._id,
            userVoucherId: voucher._id,
        }
        if (voucher.voucherTypeId.purchaseOrder && voucher.voucherTypeId.purchaseOrder.trim()) voucherOrderDetails.purchaseOrder = voucher.voucherTypeId.purchaseOrder
        if (address.district) voucherOrderDetails.district = address.district
        if (address.altContact) voucherOrderDetails.altContact = address.altContact

        handleOpenPopup();

        // const loading = toast.loading("requesting...");
        // try {
        //     const response: any = await placeVoucherOrderv2(voucherOrderDetails);
        //     if (response?.data) {
        //         toast.dismiss(loading)
        //         setShowSuccess(true)
        //         setTimeout(() => {
        //             setbuttonLoading(false);
        //         }, 1000);
        //     } else {
        //         toast.dismiss(loading);
        //         toast.error(response?.errRes.data?.message);
        //         setTimeout(() => {
        //             setbuttonLoading(false);
        //         }, 1000);
        //     }
        // } catch (error: any) {
        //     toast.dismiss(loading);
        //     toast.error(error?.errRes.data?.message);
        //     setTimeout(() => {
        //         setbuttonLoading(false);
        //     }, 1000);
        // }

    }

    useEffect(() => {
        const confirmOrder = async () => {
            const selectedProducts = getSelectedItems()
            const voucherOrderDetails: VoucherOrderData = {
                name: address.firstName + " " + address.lastName,
                email: address.email,
                contactNo: address.phoneNo,
                address: address.address,
                city: address.city,
                state: address.state,
                country: address.country,
                pincode: address.pincode,
                data: selectedProducts,
                department: voucher.department,
                voucherType: voucher.voucherTypeId._id,
                userVoucherId: voucher._id,
            }
            if (voucher.voucherTypeId.purchaseOrder && voucher.voucherTypeId.purchaseOrder.trim()) voucherOrderDetails.purchaseOrder = voucher.voucherTypeId.purchaseOrder
            if (address.district) voucherOrderDetails.district = address.district
            if (address.altContact) voucherOrderDetails.altContact = address.altContact

            const loading = toast.loading("requesting...");
            try {
                const response: any = await placeVoucherOrderv2(voucherOrderDetails);
                if (response?.data) {
                    toast.dismiss(loading)
                    setConfirmOrder(false);
                    setShowSuccess(true)
                    setTimeout(() => {
                        setbuttonLoading(false);
                    }, 1000);
                } else {
                    toast.dismiss(loading);
                    setConfirmOrder(false);
                    toast.error(response?.errRes.data?.message);
                    setTimeout(() => {
                        setbuttonLoading(false);
                    }, 1000);
                }
            } catch (error: any) {
                toast.dismiss(loading);
                setConfirmOrder(false);
                toast.error(error?.errRes.data?.message);
                setTimeout(() => {
                    setbuttonLoading(false);
                }, 1000);
            }


        }
        if (ConfirmOrder) {
            confirmOrder();
        }
    }, [ConfirmOrder])

    return (voucher && !voucher.isClaimed) ? (

        <div className="nc-PageHome relative overflow-hidden">
            {
                voucher && (
                    <SuccessModal
                        showModal={showSuccess}
                        successMessage="Order Placed Successfully."
                        redirectMessage="Redirecting to orders page..."
                        path={"/my-order/voucher/" + voucher._id}
                    />
                )
            }
            <Helmet>
                <title>Vouchers Combo</title>
            </Helmet>
            <div className="bg-gray-300">
                {/* <ButtonPrimary>Select</ButtonPrimary> */}
                <SectionHero2 data={voucher && voucher.voucherTypeId ? [voucher.voucherTypeId.sliderImage] : null} />
            </div>
            <div className=" mt-20 px-10">
                <Heading className="font-bold text-gray-700 text-2xl capitalize">Welcome {userDetails.username}</Heading>
                {/* Converted the html content to raw */}
                <p className="text-gray-500 mt-3" dangerouslySetInnerHTML={{ __html: !!voucher ? voucher.voucherTypeId.congratulatoryText : "Congratulations" }} />

            </div>
            <div className="py-6 pt-24 container">
                {
                    isCombo ? (
                        <ProductSelectionSection
                            data={data}
                            handleSelectProduct={handleSelectComboProduct}
                        />
                    ) : (
                        <ProductSelectionSection
                            data={data}
                            handleSelectProduct={handleSelectProduct}
                        />
                    )
                }
                <div className="mt-12 sm:mt-16 space-y-10 sm:space-y-16">
                    <div className="mt-20 ">
                        <Heading className="font-bold text-gray-700 text-2xl capitalize">SHIPPING DETAILS </Heading>
                        <p className="text-gray-500 mt-3">
                            Please provide the below details. The delivery will be made to the below   mentioned address.
                        </p>
                    </div>
                    <AddressForm value={address} onChange={handleAddressChange} />
                    <div className="">
                        <ButtonPrimary className="sm:!px-7 px-7 shadow-none" disabled={buttonLoading} onClick={handleSubmit}>
                            {buttonLoading ? (<svg aria-hidden="true" className="w-6 h-6 flex justify-center item-center mx-7 text-gray-200 animate-spin dark:text-gray-600 fill-primary-6000 " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                            </svg>) : " Place Order"}
                        </ButtonPrimary>
                    </div>
                    <hr className="border-slate-200 dark:border-slate-700" />
                </div>
            </div>
            <OrderConfirmation onOrderConfirm={() => {
                setConfirmOrder(true)
            }} data={data} address={address} show={showOrderConfirmation} closePopup={handleClosePopup} />
        </div>
    ) : (

        <div className="nc-PageHome relative overflow-hidden pb-[20vh]">
            {
                voucher && (
                    <SuccessModal
                        showModal={showSuccess}
                        successMessage="Order Placed Successfully."
                        redirectMessage="Redirecting to orders page..."
                        path={"/my-order/voucher/" + voucher._id}
                    />
                )
            }
            <Helmet>
                <title>Vouchers Combo</title>
            </Helmet>
            <div className="bg-gray-300">
                <SectionHero2 data={voucher && voucher.voucherTypeId ? [voucher.voucherTypeId.sliderImage] : null} />
            </div>
            <div className="mt-20 px-10">
                <Heading className="font-bold text-gray-700 text-2xl capitalize">Welcome {userDetails.username}</Heading>
                {/* <p className="text-gray-500 mt-3">{!!voucher ? voucher.voucherTypeId.congratulatoryText : "Congratulations"}</p> */}
                <p className="text-gray-500 mt-3" dangerouslySetInnerHTML={{ __html: !!voucher ? voucher.voucherTypeId.congratulatoryText : "Congratulations" }} />

            </div>
            <div className="mx-auto mt-24">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto" xmlnsXlink="http://www.w3.org/1999/xlink" width={100} height={100} version="1.1" id="Capa_1" viewBox="0 0 50 50" xmlSpace="preserve">
                        <circle style={{ fill: '#25AE88' }} cx="25" cy="25" r="25" />
                        <polyline style={{ fill: 'none', stroke: '#FFFFFF', strokeWidth: 2, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10 }} points="38,15 22,33 12,25" />
                    </svg>
                </div>
                <h3 className="text-center font-semibold text-primary-6000 text-2xl mt-5">Voucher Already Claimed!!</h3>
                <p className="text-center mt-5">Go to orders page to view the voucher order details</p>
                <div className="flex flex-row justify-center mt-2">
                    <ButtonSecondary onClick={() => navigate("/account-my-order")} className="border border-2 border-black">
                        Click Here
                    </ButtonSecondary>
                </div>
            </div>
            <OrderConfirmation onOrderConfirm={() => {
                setConfirmOrder(true)
            }} data={data} address={address} show={showOrderConfirmation} closePopup={handleClosePopup} />
        </div>
    )
};

export default MyVoucherDetailsPage;
