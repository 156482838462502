import { axiosInstance } from "../../index";

interface EcommPaymentDetails {
  name: string,
  email: string,
  contactNo: string,
  altContact: string,
  address: string,
  data: Array<string>,
  paymentGateway: string,
}

interface EcommPaymentDetailsv2 {
  name: string,
  email: string,
  contactNo: string,
  altContact?: string,
  address: string,
  district?: string,
  city: string,
  state: string,
  country: string,
  pincode: string,
  data: Array<string>,
  paymentGateway: string,
}

const placeEcommOrder = async (body: EcommPaymentDetails) => {
  try {
    const res = await axiosInstance.post("/customer/place-order-by-ecomm", body);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

const placeEcommOrderv2 = async (body: EcommPaymentDetailsv2) => {
  try {
    const res = await axiosInstance.post("/customer/place-order-by-ecomm-v2", body);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};
const getEcommOrders = async () => {
  try {
    const res = await axiosInstance.get("/customer/list-ecomm-based-order");
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
}

const getEcommOrderDetails = async (id: string) => {
  try {
    const res = await axiosInstance.get("/customer/list-ecomm-based-order-detail/" + id);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
}


const getEcommOrderDetailsById = async (id: string) => {
  try {
    const res = await axiosInstance.get("/customer/get-ecomm-based-order-detail-by-id/" + id);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
}



const cancelEcommOrder = async (id: string) => {
  try {
    const res = await axiosInstance.post("/customer/cancel-ecomm-order/" + id);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
}

const verifyPayment = async (details: { paymentGateway: string, paymentId: string, orderId: string }) => {
  try {
    const res = await axiosInstance.post("/customer/verify-order", details);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
}

interface PointsOrderDetails {
  data: Array<string>,
  purchaseOrder: string,
  name: string,
  email: string,
  contactNo: string,
  altContact: string,
  address: string,
}

interface PointsOrderDetailsv2 {
  data: Array<string>,
  purchaseOrder: string,
  name: string,
  email: string,
  contactNo: string,
  altContact?: string,
  address: string,
  district?: string,
  city: string,
  state: string,
  country: string,
  pincode: string,
}

const placePointsOrder = async (details: PointsOrderDetails) => {
  try {
    const res = await axiosInstance.post("/customer/place-points-order", details);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
}

const placePointsOrderv2 = async (details: PointsOrderDetailsv2) => {
  try {
    const res = await axiosInstance.post("/customer/place-points-order-v2", details);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
}

const getPointOrders = async () => {
  try {
    const res = await axiosInstance.get("/customer/list-points-based-order");
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
}

const getPointOrderDetails = async (id: string) => {
  try {
    const res = await axiosInstance.get("/customer/list-points-based-order-detail/" + id);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
}

interface VoucherOrderDetailsv2 {
  name: string,
  email: string,
  address: string,
  district?: string,
  altContact?: string,
  contactNo: string,
  state: string,
  city: string,
  country: string,
  pincode: string,
  purchaseOrder?: string,
  voucherType: string,
  data: Array<{ product: string, entity: string | null }>,
  userVoucherId: string,
}

const placeVoucherOrder = async (orderDetails: {
  name: string,
  email: string,
  address: string,
  contactNo: string,
  altContact: string,
  // data: Array<{product: string, entity: string | null}>,
  data: Array<{ product: string, entity: string | null }>,
  purchaseOrder: string,
  voucherType: string,
  userVoucherId: string
}) => {
  try {
    const res = await axiosInstance.post("/customer/create-voucher-order", orderDetails);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
}


const placeVoucherOrderv2 = async (orderDetails: VoucherOrderDetailsv2) => {
  try {
    const res = await axiosInstance.post("/customer/create-voucher-order-v2", orderDetails);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
}

const getVoucherOrders = async () => {
  try {
    const res = await axiosInstance.get("/customer/list-voucher-based-orders");
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
}

const getVoucherOrderDetails = async (id: string) => {
  try {
    const res = await axiosInstance.get(`/customer/list-voucher-based-order-details/${id}`);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
}

export {
  placeEcommOrder,
  placeEcommOrderv2,
  getEcommOrders,
  getEcommOrderDetails,
  cancelEcommOrder,
  verifyPayment,
  placePointsOrder,
  placePointsOrderv2,
  getPointOrders,
  getPointOrderDetails,
  placeVoucherOrder,
  placeVoucherOrderv2,
  getVoucherOrders,
  getVoucherOrderDetails,
  getEcommOrderDetailsById
}