import cookie from "js-cookie";
import axios from 'axios';
import { getUserDetails } from "./services/account";

// set in cookie
export const setCookie = (key: any, value: any) => {
  if (typeof window !== "undefined") {
    cookie.set(key, value, {
      expires: 1,
    });
  }
};

// remove from cookie
export const removeCookie = (key: any) => {
  if (typeof window !== "undefined") {
    cookie.remove(key);
  }
};

// get from cookie such as stored token
// will be useful when we need to make request to server with auth token
export const getCookie = (key: any, req: any) => {
  return typeof window !== "undefined"
    ? getCookieFromBrowser(key)
    : getCookieFromServer(key, req);
};

export const getCookieFromBrowser = (key: string) => {
  return cookie.get(key);
};

export const getCookieFromServer = (key: any, req: any) => {
  if (!req.headers.cookie) {
    return undefined;
  }

  let token = req.headers.cookie
    .split(";")
    .find((c: string) => c.trim().startsWith(`${key}=`));
  if (!token) {
    return undefined;
  }
  let tokenValue = token.split("=")[1];
  return tokenValue;
};

// set in localstoarge
export const setLocalStorage = (key: any, value: any) => {
  if (typeof window !== "undefined") {
    localStorage.setItem(key, JSON.stringify(value));
  }
};
// get from localstorage
export const getLocalStorage: any = (key: any) => {
  if (typeof window !== "undefined") {
    if (localStorage.getItem(key)) {
      return JSON.parse(localStorage.getItem(key) || "");
    }
    else {
      return false
    }
  }
};
// remove from localstorage
export const removeLocalStorage = (key: any) => {
  if (typeof window !== "undefined") {
    localStorage.removeItem(key);
  }
};

// authenticate user by passing data to cookie and localstorage during signin
export const authenticate = (response: any, next: any) => {
  setCookie("token", response.data.token);
  setLocalStorage("user", response.data);
  next();
};

// access user info from localstorage
export const isAuth = () => {
  if (typeof window !== "undefined") {
    const cookieChecked = getCookie("token", null);
    if (cookieChecked) {
      if (localStorage.getItem("user")) {
        return JSON.parse(localStorage.getItem("user") || "");
      } else {
        return false;
      }
    }
  }
};

export const userDetails = async () => {
  if (typeof window !== "undefined") {
    const cookieChecked = getCookie("token", null);
    if (cookieChecked) {
      if (localStorage.getItem("user")) {
        const res = await getUserDetails()
        return res.data;
      } else {
        return false;
      }
    }
  }
};

export const userDetailCartPage = async () => {
  if (typeof window !== "undefined") {
    const cookieChecked = getCookie("token", null);
    if (cookieChecked) {
      if (localStorage.getItem("user")) {
        const res = await getUserDetails()
        return res.data;
      } else {
        return false;
      }
    }
  }
};

export const logout = () => {
  removeCookie("token");
  removeLocalStorage("user");
  removeLocalStorage("user_details");
};

export const updateUser = (user: any, next: any) => {
  if (typeof window !== "undefined") {
    if (localStorage.getItem("user")) {
      let auth = JSON.parse(localStorage.getItem("user") || "");
      auth = user;
      localStorage.setItem("user", JSON.stringify(auth));
      next();
    }
  }
};