import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import twFocusClass from "utils/twFocusClass";

export interface PaginationProps {
    currentPage: number;
    totalCount: number;
    pageSize: number;
    onPageChange: (page: number) => void;
    className?: string;
}

const Pagination: FC<PaginationProps> = ({
    currentPage,
    totalCount,
    pageSize,
    onPageChange,
    className = "",
}) => {
    const totalPages = Math.ceil(totalCount / pageSize);

    const handleClick = (page: number) => {
        if (page >= 1 && page <= totalPages) {
            onPageChange(page);
        }
    };

    const renderPageNumbers = () => {
        const pages = [];

        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(renderItem(i));
            }
        } else {
            pages.push(renderItem(1));
            if (currentPage > 3) pages.push(<span key="ellipsis1">...</span>);

            const startPage = Math.max(2, currentPage - 1);
            const endPage = Math.min(totalPages - 1, currentPage + 1);

            for (let i = startPage; i <= endPage; i++) {
                pages.push(renderItem(i));
            }

            if (currentPage < totalPages - 2) pages.push(<span key="ellipsis2">...</span>);
            pages.push(renderItem(totalPages));
        }

        return pages;
    };

    const renderItem = (page: number) => {
        if (page === currentPage) {
            return (
                <span
                    key={page}
                    className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white ${twFocusClass()}`}
                >
                    {page}
                </span>
            );
        }
        return (
            <Link
                key={page}
                className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
                to="#"
                onClick={(event) => {
                    event.preventDefault(); // Prevents any navigation, even to "#"
                    handleClick(page); // Calls your click handler with the page number
                }}
            >
                {page}
            </Link>
        );
    };

    // Render "No search results" message if no data is available
    if (totalCount === 0) {
        return (
            <div className="text-center text-neutral-500 dark:text-neutral-400 py-4">
                No search results found
            </div>
        );
    }

    return (
        <nav className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}>
            <button
                onClick={() => handleClick(currentPage - 1)}
                disabled={currentPage === 1}
                className=""
            >
                <ArrowLeftIcon className="w-4 h-4 ml-2.5" />
            </button>

            {renderPageNumbers()}

            <button
                onClick={() => handleClick(currentPage + 1)}
                disabled={currentPage === totalPages}
            >
                <ArrowRightIcon className="w-4 h-4 ml-2.5" />
            </button>
        </nav>
    );
};

export default Pagination;
