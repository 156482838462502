import { Helmet } from "react-helmet-async";
import { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import DynamicTable from "components/CustomTable";
import Heading from "components/Heading/Heading";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Popover, Transition } from "@headlessui/react";
import Checkbox from "shared/Checkbox/Checkbox";
import ButtonThird from "shared/Button/ButtonThird";
import { hrVoucherTypeListing } from "network/services/HR";
import { useEffect } from "react";
import toast from "react-hot-toast";
import Pagination from "containers/HrDashboard/Pagination/page";
import Button from "shared/Button/Button";

const HrDashboard = () => {
  interface VoucherTypeItem {
    slno: string;
    id: string;
    voucherTypeName: string;
    connectedHR: string;
    connectedHRName: string;
    connectedHREmail: string;
    createdAt: string;
  }

  interface ApiResponse {
    data?: {
      vouchers: VoucherTypeItem[];
      totalCount: number;
      totalPages: number;
      currentPage: number;
    };
    message?: string;
  }
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const itemsPerPage = 10;
  const [tableData, setTableData] = useState<VoucherTypeItem[]>([]);
  const [showSearchForm, setShowSearchForm] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');


  const [tableHeaders, setTableHeader] = useState([
    { name: "slno", key: "slno" },
    { name: "Name Of the voucher", key: "voucherTypeName" },
    { name: "Name of the POC", key: "connectedHRName" },
    { name: "Email of the POC", key: "connectedHREmail" },
    { name: "Requested Date", key: "createdAt" },
  ]);

  const ConvertDateObjToHumanReadableDateString = (dateStr: string) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })
  }

  const getVoucherTypesByUserId = async (currentPage: number, itemsPerPage: number, searchQuery: string) => {
    try {
      const resp = await hrVoucherTypeListing(currentPage, itemsPerPage, searchQuery);
      if (resp?.data?.vouchers) {
        let count = 1;
        const userData: VoucherTypeItem[] = resp?.data?.vouchers.map((vouchertype: any) => ({
          slno: count++,
          id: vouchertype._id,
          voucherTypeName: vouchertype.voucherTypeName,
          connectedHR: vouchertype.connectedHR._id,
          connectedHRName: vouchertype.connectedHR.name,
          connectedHREmail: vouchertype.connectedHR.email,
          createdAt: ConvertDateObjToHumanReadableDateString(vouchertype.createdAt)
        }));
        setTotalPages(resp.data.totalPages);
        setTableData(userData);
      } else {
        toast.error(resp?.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    const response = getVoucherTypesByUserId(currentPage, itemsPerPage, searchQuery);
  }, [])


  // Function to handle search input change
  const handleSearchInputChange = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = async () => {
    getVoucherTypesByUserId(1, itemsPerPage, searchQuery)
  }

  const handleClearSearch = () => {
    setSearchQuery("");
    getVoucherTypesByUserId(1, itemsPerPage, "")
  }

  // Function to filter table data based on search query
  const filterTableData = (data: any) => {
    return data.filter((item: any) =>
      Object.values(item).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  };


  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    getVoucherTypesByUserId(pageNumber, itemsPerPage, searchQuery);
  };


  const renderMagnifyingGlassIcon = () => {
    return (
      <svg
        width={22}
        height={22}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 22L20 20"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const renderSearchForm = () => {
    return (
      <div className="flex items-center space-x-2 w-full">
        <div className="flex-1 py-2 text-slate-900 dark:text-slate-100">
          <div className="bg-slate-50 dark:bg-slate-800 flex items-center px-5 h-full rounded">
            <input
              type="text"
              placeholder="Search by Voucher Name"
              className="border-none bg-transparent focus:outline-none focus:ring-0 w-full text-base"
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
          </div>
        </div>
        <ButtonPrimary onClick={handleSearch}>Search</ButtonPrimary>
        <Button className="rounded-full border border-red-400 hover:bg-red-200 hover:text-white text-md font-bold" onClick={handleClearSearch}>Clear</Button>
      </div>
    );
  };


  const renderXClear = () => {
    return (
      <span className="flex-shrink-0 w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };


  return (
    <div className="container">
      <Helmet>
        <title>Hr-Vouchers</title>
      </Helmet>
      <div className="mb-12 sm:mb-16">
        <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
          <Link to={"/hr-dashboard"} className="">
            My Menu
          </Link>
          <span className="text-xs mx-1 sm:mx-1.5">/</span>
          <span className="underline">View Vouchers</span>
        </div>
      </div>
      <Heading children="Track Vouchers" />
      <div className="mt-6 rounded mb-24 lg:mt-4 border border-slate ">
        <div className="bg-white rounded ">
          <div className="flex items-center justify-between pb-4 m-4 pt-4">
            {renderSearchForm()}
          </div>
          <DynamicTable path="/hr-vouchers-details" tableData={tableData} tableHeader={tableHeaders} />
          <div className="flex justify justify-center py-4">
            <Pagination
              currentPage={currentPage}
              totalCount={totalPages * itemsPerPage}
              pageSize={itemsPerPage}
              onPageChange={handlePageChange}
            />
            {/* <ButtonPrimary loading>Show me more</ButtonPrimary> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HrDashboard;
