import { axiosInstance } from "../../index";

const createCatalogue = async (payload: any) => {
  try {
    const res = await axiosInstance.post("customer/create-catalog", payload);
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};
const addCatalogue = async (payload: any) => {
  try {
    const res = await axiosInstance.post(
      "/customer/add-product-to-catalog",
      payload
    );
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};
const editCatalogueName = async (payload: any, id: string) => {
  try {
    const res = await axiosInstance.put(
      `/customer/rename-catalog/${id}`,
      payload
    );
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

const getCatalogue = async () => {
  try {
    const res = await axiosInstance.get("/customer/list-catalogs");
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};
const deleteCatalogue = async (payload: any, id: string) => {
  try {
    const res = await axiosInstance.delete(
      `/customer/remove-catalog/${id}`,
      { data: payload }
    );
    const data = res && res.data;
    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

const downloadCatalog = async (id: string) => {
  try {
    const res = await axiosInstance.get(
      `/customer/download-catalog/${id}`
    );
    const data = res && res.data;

    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

const listUsersByCompanyId = async (
  currentpage: number | null = null,
  limit: number | null = null,
  searchQuery: string = ""
) => {
  try {
    const params: Record<string, string | number> = {};

    if (currentpage !== null) params.currentpage = currentpage;
    if (limit !== null) params.limit = limit;
    if (searchQuery) params.searchQuery = searchQuery;

    const res = await axiosInstance.get(`/customer/list-all-users-by-companyId`, { params });
    const data = res?.data;

    return { data };
  } catch (err: any) {
    const errRes = err?.response || "Network Error";
    return { errRes };
  }
};



const listAllPointsByUserId = async (
  id: string,
  currentPageForPointsCredited: number | null = null,
  itemsPerPageForPointsCredited: number | null = null,
  itemsPerPageForPointsDebited: number | null = null,
  currentPageForPointsDebited: number | null = null,
) => {
  try {
    const params: Record<string, string | number> = {};

    if (currentPageForPointsCredited !== null) params.currentPageForPointsCredited = currentPageForPointsCredited;
    if (itemsPerPageForPointsCredited !== null) params.itemsPerPageForPointsCredited = itemsPerPageForPointsCredited;
    if (itemsPerPageForPointsDebited !== null) params.itemsPerPageForPointsDebited = itemsPerPageForPointsDebited;
    if (currentPageForPointsDebited !== null) params.currentPageForPointsDebited = currentPageForPointsDebited;

    const res = await axiosInstance.get(
      `/customer/list-all-points-by-userId/${id}`,
      { params }
    );

    return { data: res?.data };
  } catch (err: any) {
    const errRes = (err?.response || "Network Error");
    return { errRes };
  }
};


const hrVoucherTypeListing = async (
  currentpage: number | null = null,
  limit: number | null = null,
  searchQuery: string | string = ""
) => {
  try {
    const params: Record<string, string | number> = {};

    if (currentpage !== null) params.currentpage = currentpage;
    if (limit !== null) params.limit = limit;
    if (searchQuery !== null) params.searchQuery = searchQuery;

    const res = await axiosInstance.get(
      `/customer/list-vouchertypes`,
      { params }
    );
    const data = res && res.data;

    console.log(data, "Data")

    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};


const hrListVoucherByVoucherType = async (id: string) => {
  try {
    const res = await axiosInstance.get(
      `customer/list-vouchers-by-vouchertype/${id}`
    );

    const data = res && res.data;

    return { data };
  } catch (err: any) {
    const errRes = (err && err.response) || "Network Error";
    return { errRes };
  }
};

export {
  addCatalogue,
  createCatalogue,
  getCatalogue,
  editCatalogueName,
  deleteCatalogue,
  downloadCatalog,
  listUsersByCompanyId,
  listAllPointsByUserId,
  hrVoucherTypeListing,
  hrListVoucherByVoucherType
};
