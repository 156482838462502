import { getLocalStorage } from "network/helper";
import { useEffect, FC } from "react"
import { useNavigate } from "react-router-dom"

interface SuccessModalProps {
    successMessage: string;
    redirectMessage: string;
    showModal: boolean;
    state: object;
    type: string;
    OrderId: string;
}

const SuccessModal: FC<SuccessModalProps> = ({
    successMessage,
    state,
    OrderId,
    type,
    redirectMessage,
    showModal,
}) => {
    const navigate = useNavigate()



    useEffect(() => {
        if (showModal) {
            setTimeout(() => {
                navigate(`/my-order/${type}/${OrderId} `, { state: state })
            }, 2000)
        }
    }, [showModal])

    return (
        <div className={showModal ? "fixed top-0 left-0 z-20 w-screen h-screen bg-white" : "hidden"}>
            <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto" xmlnsXlink="http://www.w3.org/1999/xlink" width={100} height={100} version="1.1" id="Capa_1" viewBox="0 0 50 50" xmlSpace="preserve">
                        <circle style={{ fill: '#25AE88' }} cx="25" cy="25" r="25" />
                        <polyline style={{ fill: 'none', stroke: '#FFFFFF', strokeWidth: 2, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10 }} points="38,15 22,33 12,25" />
                    </svg>
                </div>
                <h3 className="text-center font-semibold text-primary-6000 text-2xl mt-5">{successMessage}</h3>
                <p className="text-center">{redirectMessage}</p>
            </div>
        </div>
    )
}

export default SuccessModal;