import React, { FC, useEffect, useState } from "react";
import Button from "shared/Button/Button";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonClose from "shared/ButtonClose/ButtonClose";

interface Address {
    firstName: string;
    lastName: string;
    email: string;
    phoneNo: string;
    address: string;
    district: string;
    city: string;
    state: string;
    country: string;
    altContact: string;
    pincode: string;
    _id: string;
}

type ProductEntity = {
    productName: string;
    entityName: string;
};

interface Entity {
    _id: string;
    name: string;
}

interface Product {
    _id: string;
    name: string;
    description: string;
    images: string[];
    entities: Entity[];
}

interface Data {
    category: string;
    selected: { product: string; entity: string | null };
    products: Product[];
}

interface OrderConfirmationProps {
    show: boolean;
    closePopup: () => void;
    address: Address;
    productImage?: string;
    data: Data[];
    onOrderConfirm: () => void;
}

const OrderConfirmation: FC<OrderConfirmationProps> = ({
    show,
    closePopup,
    address,
    data,
    onOrderConfirm,
}) => {
    const [selectedProductDetails, setSelectedProductDetails] = useState<Product[] | null>([]);
    const [ProductAndEntityDetails, setProductAndEntityDetails] = useState<ProductEntity[]>([]);

    // Fetch product details based on selected product ID
    const fetchSelectedProducts = () => {
        return data
            .map((category) =>
                category.products.find((product) => product._id === category.selected.product)
            )
            .filter((product): product is Product => !!product); // Filter out undefined entries
    };

    useEffect(() => {
        if (data.length > 0) {
            const products = fetchSelectedProducts();
            setSelectedProductDetails(products);
            const resp = fetchSelectedProductsList()
            setProductAndEntityDetails(resp);
            console.log(resp, "Resp")
        }
    }, [data]);

    // Fetch a list of selected products and entities
    const fetchSelectedProductsList = () => {
        return data.map((item) => {
            const selectedProduct = item.products.find(
                (p: Product) => p._id === item.selected.product
            );
            const selectedEntity = selectedProduct?.entities.find(
                (e: Entity) => e._id === item.selected.entity
            );
            return {
                productName: selectedProduct?.name || "N/A",
                entityName: selectedEntity?.name || "N/A",
            };
        });
    };

    // Handle order confirmation
    const handleOrderConfirm = () => {
        onOrderConfirm();
        closePopup();
    };

    return (
        <div
            className={`${show ? "block" : "hidden"} fixed top-0 left-0 w-screen h-screen z-[40] overflow-hidden`}
        >
            <div
                onClick={closePopup}
                className="absolute top-0 left-0 w-screen h-screen bg-black/50 z-[-10]"
            ></div>

            <div className="w-full h-full lg:w-[80vw] lg:max-w-[1200px] lg:max-h-[90vh] absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 bg-white p-6 lg:rounded-xl">
                <ButtonClose onClick={closePopup} className="absolute right-3 top-3" />
                <div className="nc-ProductQuickView overflow-y-auto overflow-hidden h-full w-full no-scrollbar my-3">
                    <div className="lg:flex h-full">
                        {/* Selected Products Section */}
                        <div className="overflow-y-auto h-100 w-full lg:w-[50%] flex items-start justify-start p-4 lg:border-r border-gray-200">
                            <div className="grid grid-cols-1 gap-8">
                                {selectedProductDetails && selectedProductDetails.length > 0 ? (
                                    selectedProductDetails.map((product) => (
                                        <div key={product._id} className="border p-4 rounded-lg">
                                            <img
                                                src={product.images[0]}
                                                alt={product.name}
                                                className="w-3/4 h-auto object-cover rounded-lg"
                                            />
                                            <div className="py-4">
                                                <h4 className="text-lg font-medium text-gray-700">
                                                    {product.name}
                                                </h4>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="w-full h-full flex items-center justify-center bg-gray-200 rounded-xl">
                                        <span className="text-xl text-gray-500">
                                            No Selected Products Available
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Order Confirmation Details */}
                        <div className="w-full lg:w-[50%] pt-6 lg:pt-5 lg:pl-7 xl:pl-8 flex flex-col justify-between overflow-y-auto">
                            <div className="space-y-4">
                                <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                                    Check Your Order Details
                                </h2>
                                {ProductAndEntityDetails.map((item, index) => (
                                    <div key={index} className="grid grid-cols-1">
                                        <div className="flex flex-row">
                                            <h1 className="text-lg"><strong>Product :</strong></h1>
                                            <p className="items-center mt-1 mx-1">{item?.productName}</p>
                                        </div>

                                        <div className="flex flex-row">
                                            <h1 className="text-lg"><strong>Entity :</strong></h1>
                                            <p className="items-center my-1 mx-1">{item?.entityName}</p>
                                        </div>
                                    </div>
                                ))}


                                <hr className="border-slate-200 dark:border-slate-700 mb-4" />
                                <div className="space-y-2 text-left border border-gray-200 p-4 rounded-md">
                                    <h3 className="text-md font-semibold mb-2">Shipping Address</h3>
                                    <p>
                                        <strong>Full Name:</strong> {address.firstName}{" "}
                                        {address.lastName}
                                    </p>
                                    <p>
                                        <strong>Email:</strong> {address.email}
                                    </p>
                                    <p>
                                        <strong>Phone:</strong> {address.phoneNo}
                                    </p>
                                    {address.altContact && (
                                        <p>
                                            <strong>Alternate Phone:</strong> {address.altContact}
                                        </p>
                                    )}
                                    <p>
                                        <strong>Address:</strong> {address.address}
                                    </p>
                                    {address.district && (
                                        <p>
                                            <strong>District:</strong> {address.district}
                                        </p>
                                    )}
                                    <p>
                                        <strong>City:</strong> {address.city}
                                    </p>
                                    <p>
                                        <strong>State:</strong> {address.state}
                                    </p>
                                    <p>
                                        <strong>Country:</strong> {address.country}
                                    </p>
                                    <p>
                                        <strong>Pincode:</strong> {address.pincode}
                                    </p>
                                </div>
                                <p className="text-gray-500">
                                    Please review your order. Unfortunately, we cannot process item
                                    exchanges or changes after your order has been placed.
                                </p>
                            </div>

                            <div className="flex justify-between items-center mt-4">
                                <Button onClick={closePopup} className="bg-gray-200">
                                    Update
                                </Button>
                                <ButtonPrimary onClick={handleOrderConfirm}>
                                    Confirm Order
                                </ButtonPrimary>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderConfirmation;
